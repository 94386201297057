<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-2" size="lg" title="Tambah Paket Sub Kegiatan" ok-only ok-title="Accept" hide-footer
            no-close-on-backdrop>
            <validation-observer ref="simpleRules">
                <b-row> 
                    <b-col md="12">
                       
                        <b-row>
                            <b-col md="12">
                                <b-form-group label="Nama Rekanan" label-for="basicInput"   >
                                    <validation-provider #default="{ errors }" name="Nama Rekanan" rules="required"  v-if="input_data.jm =='0'">
                                        <b-form-input v-model="input.nama_rekanan"
                                            :state="errors.length > 0 ? false:null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group> 
                                <b-form-input v-model="input.nama_rekanan" disabled    v-if="input_data.jm != '0'"/>
                            </b-col> 
                        </b-row>
                        <b-row>
                            <b-col md="7">
                                <b-form-group label="Nama Pimpinan" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Nama Pimpinan" rules="required">
                                        <b-form-input v-model="input.nama_pimpinan"
                                            :state="errors.length > 0 ? false:null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col> 
                            <b-col md="5">
                                <b-form-group label="Ho Tlp/Hp Rekanan" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Cp Perusahaan" rules="required">
                                        <b-form-input v-model="input.no_tlp_kantor"
                                            :state="errors.length > 0 ? false:null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col> 
                        </b-row> 
                        
                        <b-form-group label="Alamat" label-for="basicInput">
                            <b-form-textarea id="textarea-default" placeholder="Alamat" rows="3" v-model="input.alamat" />
                        </b-form-group>
                        <b-form-group label="Profil Rekanan" label-for="basicInput">
                            <b-form-textarea id="textarea-default" placeholder="Profil Singkat Rekanan" rows="3" v-model="input.keterangan" />
                        </b-form-group>

                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col>
                        <b-button variant="primary" @click.prevent="validationForm"> update</b-button>
                        <b-button variant="warning" class="ml-1" @click="close()"> Batal</b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required
    } from '@validations'

    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormTextarea,
        BCardText,
        BFormDatepicker
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import vSelect from 'vue-select' 

    export default {
        components: {
            BButton,
            ValidationProvider,
            ValidationObserver,
            BModal,
            BAlert,
            BRow,
            BCol,
            vSelect,
            BFormGroup,
            BFormInput,
            BFormSelect, 
            BFormTextarea,
            BCardText,
            BFormDatepicker,
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        emits:['updateRekanan'],
        data() {
            return { 
                required,
                
            }
        },
        props:{
            input_data : {}
        },
        computed:{
            input: {
                get: function () {
                    return this.input_data;
                },
                set: function (newValue) {
                    return this.input_data = newValue;
                },
            },
        },
        methods: {
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$root.$emit('bv::hide::modal', 'modal-2');
                        this.$emit("updateRekanan", this.input);
                        
                    }
                })
            },
            close(){
                this.$root.$emit('bv::hide::modal', 'modal-2');
            }
        },
    }
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>