<template>
  <div>
    <b-card>
      <daftar-rekanan-vue :items=items @DataEdit=DataEdit @deleteRekanan = deleteRekanan></daftar-rekanan-vue>
    </b-card>
    <tambah-rekanan-vue @insertRekanan=insertRekanan></tambah-rekanan-vue>
    <edik-rekanan :input_data=input_data @updateRekanan=updateRekanan></edik-rekanan>
  </div>
</template>

<script>
  import Base from '@/config/Mixins_base';
  import axios from '@/config/Axios';
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import TambahRekananVue from './component/TambahRekanan.vue'
  import DaftarRekananVue from './component/DaftarRekanan.vue'
  import EdikRekanan from './component/EdikRekanan.vue';
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BFormInput,
      BFormGroup,
      vSelect,
      TambahRekananVue,
      DaftarRekananVue,
      EdikRekanan,
    },
    mixins: [Base],
    data() {
      return {
        items: [],
        input_data: {}
      }
    },
    mounted() {
      this.load()
    },
    methods: {
      DataEdit(value) {
        this.input_data = value
      },
      async updateRekanan(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/setup/rekanan/update',
            data: {
              nama_rekanan: value.nama_rekanan,
              nama_pimpinan: value.nama_pimpinan,
              alamat: value.alamat,
              no_tlp_kantor: value.no_tlp_kantor,
              keterangan: value.keterangan,
              id: value.id,
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "Error", response.data.message);
            self.load();
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async deleteRekanan(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/setup/rekanan/delete',
            data: { 
              id: value.id,
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "Error", response.data.message);
            self.load();
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async load() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/setup/rekanan/load',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async insertRekanan(input) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/setup/rekanan/insert',
            data: input,
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('info', "info", response.data.message);
            self.load()

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }
  }
</script>

<style>

</style>