<template>
    <b-row>
        <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="2" sm="8" class="my-1">

        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label=" " label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                </b-input-group>
            </b-form-group>
        </b-col>
        <b-col md="2" class="my-1">
            <b-button block variant="primary" size="sm" v-b-modal.modal-1>
                <feather-icon size="15" icon="PlusIcon" /> Tambah Rekanan
            </b-button>
        </b-col>

        <b-col cols="12">
            <b-table hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(daftarPaket)="data"> 
                    <b-alert show variant="primary"
                        style="margin-left: -30px; margin-right: -20px;; margin-bottom: -10px;;">
                        <div class="alert-body">
                            <b-row>
                                <b-col md="5">
                                    <small>Nama Rekanan </small>
                                    <h4><strong>  {{ data.item.nama_rekanan}}</strong> </h4>
                                    <small>Nama Pimpinan </small>
                                    <h6><strong>{{ data.item.nama_pimpinan }}</strong>  </h6>
                                    
                                    <h6><small>No Tlp  {{ data.item.no_tlp_kantor }}</small> </h6>
                                </b-col>
                                <b-col md="5">
                                    
                                    <small>Profil Rekanan</small> 
                                    <small> <p>{{ data.item.keterangan }}</p> </small>
                                    <small>Alamat </small><br>
                                    <small> <p>{{ data.item.alamat }}</p> </small>
                                </b-col>
                                <b-col md="2">  
                                    <b-button block variant="warning" size="sm" v-b-modal.modal-2 @click="editData(data.item)">
                                        <feather-icon size="15" icon="EditIcon" /> Edit
                                    </b-button>
                                    <b-button block variant="danger" size="sm"  @click="hapus_data(data.item)" v-if="data.item.jm =='0'">
                                        <feather-icon size="15" icon="Trash2Icon" /> Hapus
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-alert>
                </template>
            </b-table>
        </b-col>
        <b-col cols="6">
            <strong>Total Row {{ totalRows }}</strong>
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BAlert
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BAlert
        },
        props : {
            items:{}
        },
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'daftarPaket',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }, ], 

            }
        },emits:["DataEdit", "deleteRekanan"],
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        
        methods: {
            editData(value){ 
                this.$emit("DataEdit", value)
            },
            hapus_data(value){
               if(confirm("apakah anda yakin akan menghapus data rekanan..?")== true){
                this.$emit("deleteRekanan", value)
               }
            },
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>